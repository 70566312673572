/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Parser } from 'html-to-react'
import { uniqueId } from 'lodash'
import IconTeaser from '../components/IconTeaser'
import Layout from '../components/Layout'

const htmlToReactParser = new Parser()

const Text = styled.div`
  padding: 0 30px;
`

const IconTeaserContainer = ({ items }) => (
  <section>
    <Layout.ContentContainer>
      <IconTeaser.Container>
        {items.map(({ title1, text, image }) => (
          <IconTeaser key={uniqueId('iconTeaser')}>
            <img alt={title1.text} src={image.url} />
            <h3>{title1.text}</h3>
            <Text>{htmlToReactParser.parse(text.html)}</Text>
          </IconTeaser>
        ))}
      </IconTeaser.Container>
    </Layout.ContentContainer>
  </section>
)

export const sliceId = 'iconteasercontainer'
export default IconTeaserContainer
