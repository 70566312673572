/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { largeText } from '../styles/typography'

const styling = css`
  display: inline-block;
  position: relative;
  padding-bottom: 83px;
  ${largeText}
  line-height: 1.07;
  max-width: 380px;
  width: 100%;

  .small-teaser-image {
    width: 100%;
  }

  .text-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #2b2b2b;
    border-top-right-radius: 75px;
    color: white;
    min-height: 160px;
    padding: 20px 33px 20px 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  p {
    margin-top: auto;
    color: #ff406f;
    border-bottom: 8px solid currentColor;
    align-self: flex-start;
    padding-bottom: 4px;
    font-family: 'neue-haas-grotesk-display';
  }
`

const SmallTeaser = ({ children, linkTo }) => (
  <a href={linkTo} css={styling}>
    {children}
  </a>
)

SmallTeaser.Text = ({ children }) => <div className="text-overlay">{children}</div>
SmallTeaser.Title = ({ children }) => <h3>{children}</h3>
SmallTeaser.LinkText = ({ children }) => <p>{children}</p>
SmallTeaser.Image = props => <img className="small-teaser-image" alt="" {...props} />

export default SmallTeaser
