import bp from '../utils/media'

const primaryHeadline = `
  font-size: 84px;
  line-height: 0.94;
  font-weight: bold;
  font-family: 'neue-haas-grotesk-display';

  ${bp.medium} {
    font-size: 64px;
    line-height: 1.07;
  }

  ${bp.small} {
    font-size: 40px;
  }
`

const secondaryHeadline = `
  font-size: 40px;
  font-weight: bold;
  line-height: 1.1;
  font-family: 'neue-haas-grotesk-display';

  ${bp.medium} {
    font-size: 36px;
  }

  ${bp.small} {
    font-size: 32px;
  }
`

const largeTextLight = `
  font-size: 28px;
  line-height: 1.1;
  font-family: 'neue-haas-grotesk-display';

  ${bp.medium} {
    font-size: 24px;
  }

  ${bp.small} {
    font-size: 20px;
  }
`

const largeText = `
  ${largeTextLight}
  font-weight: bold;
`

const secondarySubHeadline = `
  font-size: 28px;
  line-height: 1.1;
  font-family: 'neue-haas-grotesk-display';

  ${bp.medium} {
    font-size: 26px;
  }

  ${bp.small} {
    font-size: 24px;
  }
`

export { primaryHeadline, secondaryHeadline, secondarySubHeadline, largeText, largeTextLight }
