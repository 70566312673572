/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Parser } from 'html-to-react'
import Layout from '../components/Layout'
import bp from '../utils/media'

const htmlToReactParser = new Parser()

const styling = css`
  .richtext-container {
    padding: 140px 180px 100px;

    ${bp.large} {
      padding: 140px 0 100px;
    }
    ${bp.medium} {
      padding: 50px 0 40px;
    }
  }

  h1 {
    font-size: 90px;
    font-weight: bold;
    margin-left: -180px;
    margin-bottom: 60px;

    ${bp.large} {
      margin-left: 0;
    }
    ${bp.medium} {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  h3 {
    margin-top: 1em;
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin-bottom: 1em;
  }
`

const RichText = ({ title, richtext }) => (
  <section css={styling}>
    <Layout.ContentContainer>
      <div className="richtext-container">{htmlToReactParser.parse(richtext.html)}</div>
    </Layout.ContentContainer>
  </section>
)

export const sliceId = 'richtext'
export default RichText
