/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Parser } from 'html-to-react'
import { uniqueId } from 'lodash'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import TextImageContentNarrow from '../components/TextImageContentNarrow'
import Button from '../components/Button'
import Layout from '../components/Layout'
import bp from '../utils/media'

const htmlToReactParser = new Parser()

const styling = css`
  display: flex;
  padding: 125px 0;

  ${bp.medium} {
  }
`

const Text = styled.div`
  padding: 0 30px;
`

const TextImageContentNarrowSlice = ({ image, title, text, button_text, link }) => (
  <section>
    <Layout.ContentContainer>
      <TextImageContentNarrow>
        <img src={image.url} alt="" />
        <h2>{title.text}</h2>
        {htmlToReactParser.parse(text.html)}
        {button_text && (
          <Link to="subscribe" spy smooth="easeInOutCubic" duration={2000}>
            <Button href={link && link.text}>{button_text.text}</Button>
          </Link>
        )}
      </TextImageContentNarrow>
    </Layout.ContentContainer>
  </section>
)

export const sliceId = 'textimagecontentnarrow'
export default TextImageContentNarrowSlice
