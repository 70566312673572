/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { largeText } from '../styles/typography'
import bp from '../utils/media'

const containerStyling = css`
  display: flex;
  padding: 125px 0;

  ${bp.medium} {
    flex-direction: column;
    padding-bottom: 0;
    padding: 80px 0;
  }
`
const styling = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 auto;

  ${bp.medium} {
    margin-bottom: 56px;
  }

  img {
    margin-bottom: 20px;
    width: 100px;
    height: auto;
  }

  h3 {
    ${largeText}
    margin-bottom: 16px;
  }

  ${bp.small} {
  }
`

const IconTeaser = ({ children }) => <li css={styling}>{children}</li>

IconTeaser.Container = ({ children }) => <ul css={containerStyling}>{children}</ul>

export default IconTeaser
