import React, { useState } from 'react'
import { graphql } from 'gatsby'
import * as Scroll from 'react-scroll'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { kebabCase } from 'lodash'
import Layout from '../components/Layout'
import Slices from '../slices/_map'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Button from '../components/Button'

export default props => {
  const { data } = props
  const content = data.prismicLandingpage.data

  return (
    <Layout>
      {/* <Header> */}
      {/* {content.body.map(({ primary }) =>
          primary.navigationtitle && primary.navigationtitle.text !== null ? (
            <Link
              to={kebabCase(primary.navigationtitle.text)}
              spy
              smooth="easeInOutCubic"
              offset={-50}
              duration={1000}
              key={`${kebabCase(primary.navigationtitle.text)}__nav`}
            >
              {primary.navigationtitle.text}
            </Link>
          ) : null
        )} */}
      {/* <Link to="subscribe" spy smooth="easeInOutCubic" duration={2000}>
          <Button href="#" secondary noAnimation>
            subscribe
          </Button>
        </Link> */}
      {/* </Header> */}
      coming soon
      {/* <Slices body={content.body}></Slices> */}
      {/* <Footer>
        <span>Stiftstraße 43</span>
        <span>60313 Frankfurt am Main</span>
        <span>Germany</span>
        <a href="/imprint">Impressum</a>
        <strong>RXI copyright 2020</strong>
      </Footer> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query LandingPageQuery($id: String) {
    prismicLandingpage(id: { eq: $id }) {
      data {
        body {
          ... on PrismicLandingpageBodyIconteasercontainer {
            id
            items {
              image {
                url
              }
              text {
                html
              }
              title1 {
                text
              }
            }
            slice_type
            primary {
              navigationtitle {
                text
              }
            }
          }
          ... on PrismicLandingpageBodyRichtext {
            id
            primary {
              richtext {
                html
              }
              title {
                text
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyVideostage {
            id
            primary {
              h1 {
                text
              }
              h2 {
                text
              }
              text {
                html
              }
              video {
                url
              }
              image_top_right {
                url
                dimensions {
                  height
                  width
                }
              }
              image_left {
                url
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyNewslettersignup {
            id
            primary {
              description {
                text
              }
              erroremailformat {
                text
              }
              erroremailrequired {
                text
              }
              errortermsrequired {
                text
              }
              sendinblueapikey {
                text
              }
              sendinbluelistid {
                text
              }
              sendinbluetemplateid {
                text
              }
              termslabel {
                html
              }
              title1 {
                text
              }
              doubleoptinredirecturl {
                url
              }
              navigationtitle {
                text
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyTextimagecontentnarrow {
            id
            primary {
              image {
                url
              }
              text {
                html
              }
              title {
                text
              }
              button_text {
                text
              }
              link {
                text
              }
            }
            slice_type
          }
          ... on PrismicLandingpageBodyContentblockgallery {
            id
            slice_type
            items {
              image {
                url
                mobile {
                  dimensions {
                    width
                    height
                  }
                  url
                }
                dimensions {
                  height
                  width
                }
              }
            }
            primary {
              animation
              button_text {
                text
              }
              graphicsdisabled
              image_bottom_left {
                url
                dimensions {
                  height
                  width
                }
              }
              image_top_right {
                url
                dimensions {
                  height
                  width
                }
              }
              link {
                text
              }
              navigationtitle {
                text
              }
              noimageoffset
              subtitle {
                text
              }
              text {
                text
              }
              text_background {
                url
              }
              text_color
              title1 {
                text
              }
            }
          }
        }
      }
      type
    }
  }
`
