/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Parser } from 'html-to-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { uniqueId } from 'lodash'
import ContentIntro from '../components/ContentIntro'
import SmallTeaser from '../components/SmallTeaser'
import Button from '../components/Button'
import ContentBlock from '../components/ContentBlock'
import Modal from '../components/Modal'
import bp from '../utils/media'

SwiperCore.use([Navigation, Pagination])

const htmlToReactParser = new Parser()

const styling = css`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;

  ${bp.medium} {
    max-width: 380px;
    margin: 0;
  }

  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  .swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  /* Auto Height */
  .swiper-container-autoheight {
    &,
    .swiper-slide {
      height: auto;
    }

    .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height;
    }
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    padding-left: 7px;
    margin: 47px 0 7px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #1d1d1b;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;

    &-active {
      &:after {
        content: '';
        width: 4px;
        height: 4px;
        display: block;
        background: #1d1d1b;
        border-radius: 50%;
      }
    }
  }
`

const ContentBlockSlider = ({ title1, subtitle, text, items, button_text, link, modallink }) => {
  console.log(modallink)
  return (
    <ContentBlock>
      <ContentIntro>
        <ContentIntro.SubTitle>{subtitle.text}</ContentIntro.SubTitle>
        <ContentIntro.Title>{title1.text}</ContentIntro.Title>
        <ContentIntro.Text>{text.text}</ContentIntro.Text>
      </ContentIntro>
      <ContentBlock.FullWidthContent>
        <Swiper
          css={styling}
          spaceBetween={20}
          slidesPerView={1}
          loopedSlides={4}
          loop
          navigation
          pagination={{ clickable: true }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={swiper => console.log(swiper)}
          breakpoints={{
            767: {
              slidesPerView: 3,
            },
          }}
        >
          {items.map(({ title1: title, image, linktext }) => (
            <SwiperSlide key={uniqueId()}>
              <SmallTeaser>
                <SmallTeaser.Image src={image.url} alt={title.text} />
                <SmallTeaser.Text>
                  <SmallTeaser.Title>{title.text}</SmallTeaser.Title>
                  <SmallTeaser.LinkText>{linktext.text}</SmallTeaser.LinkText>
                </SmallTeaser.Text>
              </SmallTeaser>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentBlock.FullWidthContent>
      {button_text && (
        <ContentBlock.Footer>
          <Button href={link && link.text}>{button_text.text}</Button>
        </ContentBlock.Footer>
      )}
      {modallink.document.map(({ id }) => (
        <Modal id={id}>a</Modal>
      ))}
    </ContentBlock>
  )
}

export const sliceId = 'contentblockslider'
export default ContentBlockSlider
