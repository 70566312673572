import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import styled from '@emotion/styled'
import bp from '../utils/media'
import logo from '../assets/img/BildTextLogo.png'

const StyledContainer = styled.div`
  padding: 20px 0;
  left: 0;
  top: 0;
  width: 100%;
  background: #ededed;
  z-index: 222;

  .content-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .logo {
    ${bp.large} {
      width: 120px;
      height: auto;
    }
  }

  a {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 23;
  }

  nav {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-right: 8%;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 22;

    .content-container {
      & > a {
        flex-grow: 0;
        margin-left: auto;
      }
    }

    ${bp.large} {
      padding-right: 0;
    }

    a {
      flex-grow: 1;
      text-align: right;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }
    }
  }
`

const Header = ({ children }) => (
  <StyledContainer>
    <div className="content-container">
      <a href="/">
        <img src={logo} className="logo" width={141} height={61} alt="Retail Xperience Innovations" />
      </a>
      <nav>
        <div className="content-container">{children}</div>
      </nav>
    </div>
  </StyledContainer>
)

export default Header
