/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import { secondaryHeadline, largeText, largeTextLight } from '../styles/typography'
import bp from '../utils/media'

const styling = css`
  max-width: 580px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 240px;

  ${bp.medium} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  img {
    margin-bottom: 30px;
    max-width: 100%;
  }

  h3 {
    text-align: center;
    ${largeTextLight}
  }

  h2 {
    ${secondaryHeadline}
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 2em;
  }
`

const TextImageContentNarrow = ({ children }) => <div css={styling}>{children}</div>

export default TextImageContentNarrow
