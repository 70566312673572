/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'
import bp from '../utils/media'

const baseStyling = css`
  padding: 150px 0;
  position: relative;
  overflow: hidden;
  max-width: 100%;

  ${bp.medium} {
    padding: 100px 0 50px;
  }

  .content-block-footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .full-width-container {
    width: calc(100vw - 10px);
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;
  }
  svg {
    path {
      stroke: #ffaa6e;
      fill: #ffaa6e;
    }
  }
`
const backgroundStyling = css`
  position: relative;

  &:before {
    content: '';
    background: #ececec;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 70%;
    z-index: -1;
  }
`

const ContentBlock = ({ children, background }) => (
  <section
    css={css`
      ${baseStyling};
      ${background ? backgroundStyling : ''};
    `}
  >
    {background}
    {children}
  </section>
)

ContentBlock.FullWidthContent = ({ children }) => (
  <div className="full-width-container">
    <div className="content-container">{children}</div>
  </div>
)
ContentBlock.Footer = ({ children }) => <div className="content-block-footer">{children}</div>

export default ContentBlock
