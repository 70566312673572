/** @jsx jsx */
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { css, jsx } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import { createPortal } from 'react-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import bp from '../utils/media'
import Slices from '../slices/_map'

const styling = css`
  background: red;
`

const Portal = ({ children }) => createPortal(children, document.body)

const Modal = ({ id, trigger, onClick }) => {
  const [open, setOpen] = useState(false)
  const modalClassName = 'ModalComponent'

  return (
    <>
      <Portal>
        <TransitionGroup className={modalClassName}>
          {open && (
            <CSSTransition timeout={600}>
              <div className={`${modalClassName}__animation-wrapper`}>
                <div
                  onClick={() => {
                    setOpen(false)
                  }}
                  className={`${modalClassName}__background`}
                ></div>
                <section className={`${modalClassName}__content`}>
                  <div
                    className={`${modalClassName}__close`}
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    x
                  </div>
                  <div className={`${modalClassName}__content-wrapper`}>
                    <StaticQuery
                      query={graphql`
                        query ModalQuery($id: String) {
                          prismicModal(id: { eq: $id }) {
                            data {
                              body {
                                primary {
                                  title1 {
                                    text
                                  }
                                  description {
                                    html
                                  }
                                  doubleoptinredirecturl {
                                    url
                                  }
                                  erroremailformat {
                                    text
                                  }
                                  erroremailrequired {
                                    text
                                  }
                                  errortermsrequired {
                                    text
                                  }
                                  sendinblueapikey {
                                    text
                                  }
                                  sendinbluelistid {
                                    text
                                  }
                                  sendinbluetemplateid {
                                    text
                                  }
                                  termslabel {
                                    text
                                  }
                                }
                                slice_type
                              }
                            }
                          }
                        }
                      `}
                      render={data => (
                        <div css={styling}>
                          <Slices body={data.prismicModal.data.body} />
                        </div>
                      )}
                    />
                  </div>
                </section>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </Portal>
      <span
        className={`${modalClassName}-trigger`}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {trigger}
        asdf
      </span>
    </>
  )
}

export default Modal
