/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import styled from '@emotion/styled'
import Lottie from 'react-lottie-player'
import bp from '../utils/media'
import animationData1 from '../lottie/button/Filled-Dots Medium-SVG.json'
import animationData2 from '../lottie/button/Filled-LinesBold-SVG.json'
import animationData3 from '../lottie/button/Filled-QuarterLines-SVG.json'
import animationData4 from '../lottie/button/Filled-X-Pure-SVG.json'
import animationData5 from '../lottie/button/Filled-Xpattern-Medium-SVG.json'
import { largeText } from '../styles/typography'

const styling = (secondary, noAnimation) => css`
  ${largeText}
  position: relative;
  padding: 0 0 8px;
  display: inline-block;
  height: 100px;
  width: 500px;
  overflow: hidden;
  color: #313131;
  transition: 0.4s;
  text-align: center;
  z-index: 1;
  text-decoration: none;
  line-height: 100px;
  border: none;
  cursor: pointer;

  ${noAnimation &&
    css`
      background: #ffaa6e;
      width: auto;
      padding-left: 70px;
      padding-right: 70px;
    `}

  ${bp.medium} {
    width: 250px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
  }

  &:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    transform: translateY(-8px);
    background: #313131;
    transition: 0.4s cubic-bezier(0.83, 0, 0.17, 1);
    z-index: -1;
    border-bottom: 8px solid #ffaa6e;
    ${bp.medium} {
      border-bottom: 4px solid #ffaa6e;
      transform: translateY(-4px);
    }
  }

  &:hover {
    color: #ffaa6e;

    &:after {
      transform: translateY(-100%);
      ${bp.medium} {
        transform: translateY(-50px);
      }
    }
  }

  ${secondary &&
    css`
      background: transparent;
      line-height: 50px;
      height: 50px;
      color: #ffaa6e;
      padding-left: 33px;
      padding-right: 33px;
      &:after {
        border-bottom: none;
        background: #ffaa6e;
      }
      &:hover {
        color: black;
      }
      ${bp.medium} {
        width: auto;
      }
    `}

  svg {
    path {
      stroke: #ffaa6e;
      fill: #ffaa6e;
    }
  }
`

const StyledBackground = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;

  div {
    flex: 0 0 100px;
    ${bp.medium} {
      flex: 0 0 50px;
    }
  }
`

const Button = ({ children, href, type, noAnimation = false, onClick, secondary }) => {
  // Some other state
  const [play, setPlay] = useState(false)

  const [ref] = useInView(
    {
      onEnter: () => {
        setPlay(true)
      },
      onLeave: () => {
        setPlay(false)
      },
    },
    [play]
  )

  return (
    <>
      {href && (
        <a css={styling(secondary, noAnimation)} ref={ref} href={href}>
          {children}
          {!noAnimation && (
            <StyledBackground>
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData1} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData2} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData3} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData4} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData5} />
            </StyledBackground>
          )}
        </a>
      )}
      {type === 'submit' && (
        <button css={styling(secondary, noAnimation)} type="submit">
          {children}
          {!noAnimation && (
            <StyledBackground>
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData1} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData2} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData3} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData4} />
              <Lottie play={play} loop={false} height={100} width={100} animationData={animationData5} />
            </StyledBackground>
          )}
        </button>
      )}
    </>
  )
}

export default Button
